import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Controls from "./components/Controls/Controls";
import LocalVideoPreview from "./components/LocalVideoPreview/LocalVideoPreview";
import MenuBar from "./components/MenuBar/MenuBar";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import Room from "./components/Room/Room";
import InvalidParam from "./components/Room/InvalidParam";
import UnsupportedBrowser from "./components/Room/UnsupportedBrowser";
import Video from "twilio-video";

import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";
import { getQueryPfaram, getKey } from "./utils";
import { useParams } from "react-router-dom";

import RotateDevice from "./components/Room/RotateDevice";
//@ts-ignore
// import base64 from "react-native-base64";

import { useTranslation } from "react-i18next";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "auto 1fr",
});

const Main = styled("main")({
  overflow: "hidden",
});
const marginAuto = { margin: "auto" };

const validateTime = (start: string, end: string): boolean => {
  const halfAnHour = 60 * 30 * 1000;
  const startTime = parseInt(start || "0", 10) - halfAnHour;
  const endTime = parseInt(end || "0", 10) + halfAnHour;
  const curTime = new Date().getTime(); // millisecond
  // check if current time is within [startTime-30mins, endTime+30mins]
  if (curTime >= startTime && curTime <= endTime) {
    return true;
  }
  return false;
};

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const start = getQueryPfaram("start");
  const end = getQueryPfaram("end");
  const { URLRoomName } = useParams();
  // const param = getQueryPfaram("param");
  const [token, setToken] = useState("");
  useEffect(() => {
    if (!start || !end) {
      return;
    }
    getKey(URLRoomName, start, end)
      .then(token => {
        setToken(token);
      })
      .catch(() => setToken("error"));
  }, []);

  const { t } = useTranslation();
  const flex = { height: height, display: "flex" };

  if (!start || !end) {
    return <InvalidParam message={t("Invalid url, please check!")}></InvalidParam>;
  }

  if (token === "") {
    // loading
    return (
      <Container style={flex}>
        <CircularProgress style={marginAuto} />
      </Container>
    );
  }

  if (token === "error") {
    return <InvalidParam message={t("Get interview key error, please check!")}></InvalidParam>;
  }

  if (!validateTime(start, end)) {
    return (
      <InvalidParam
        message={t("Not interview time")}
        startTime={start}
        endTime={end}></InvalidParam>
    );
  }

  if (!Video.isSupported) {
    return <UnsupportedBrowser></UnsupportedBrowser>;
  }

  return (
    <Container style={{ height }}>
      <RotateDevice />
      <MenuBar urlToken={token} />
      <Main style={{ height: height }}>
        {roomState === "disconnected" ? <LocalVideoPreview /> : <Room />}
        <Controls />
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
