import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useParticipants from "../../../hooks/useParticipants/useParticipants";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    rowWrap: {
      height: 54,
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 20,
    },
    circleView: {
      width: "3em",
      height: "3em",
      borderRadius: "50%",
      backgroundColor: randomColor(0, 4),
      marginRight: "0.8em",
      marginLeft: "0.5em",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    nameLetter: {
      color: "#fff",
      fontSize: "1.6em",
    },
  }),
);

const randomColor = (min: number, max: number) => {
  const colorList = ["#3994f2", "#9983dc", "#27b7d5", "#ff616f", "#41d27c"];
  const result: number = min + Math.random() * (max - min);
  const num = Math.round(result);
  return colorList[num];
};

export default function AttendeeButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const btnLabel = t("Attendee");
  const myself = t("(Myself)");
  const [state, setState] = React.useState(false);
  const handleAttendeeListShown = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  const list = () => (
    <div
      role="presentation"
      onClick={handleAttendeeListShown(false)}
      onKeyDown={handleAttendeeListShown(false)}
      style={{ textAlign: "center" }}>
      <img
        style={{ width: "2.8em", height: "1.4em", marginTop: "0.5em" }}
        src={require("../../../assets/icons/popup_arrow.png")}
        alt=""
      />
      <List>
        <ListItem
          button
          key={localParticipant.sid}
          alignItems="center"
          onClick={event => setSelectedParticipant(localParticipant)}>
          {attendeeIcon(localParticipant.identity)}
          <ListItemText
            style={{ fontSize: "1.1rem" }}
            primary={`${localParticipant.identity} ${myself}`}
          />
        </ListItem>
        {participants.length !== 0 ? (
          <Divider light style={{ marginLeft: "1em", marginRight: "1em" }} />
        ) : null}
        {participants.map((participant, index) => (
          <div>
            <ListItem
              button
              key={participant.sid}
              alignItems="center"
              onClick={event => setSelectedParticipant(participant)}>
              {attendeeIcon(participant.identity)}
              <ListItemText style={{ fontSize: "1.1rem" }} primary={participant.identity} />
            </ListItem>
            {index !== participants.length - 1 ? (
              <Divider light style={{ marginLeft: "1em", marginRight: "1em" }} />
            ) : null}
          </div>
        ))}
      </List>
    </div>
  );

  const attendeeIcon = (name: string) => {
    // return <Text>ddxx</Text>;
    if (!name) {
      return null;
    }
    return (
      <div className={classes.circleView}>
        <span className={classes.nameLetter}>{name[0].toUpperCase()}</span>
      </div>
    );
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip title={btnLabel} placement="top" PopperProps={{ disablePortal: true }}>
        <Fab
          className={classes.fab}
          style={{
            backgroundColor: "rgba(255,255,255,0.04)",
            border: "1px solid",
            borderColor: "rgba(255,255,255,0.2)",
          }}
          onClick={handleAttendeeListShown(true)}
          disabled={props.disabled}>
          <img
            src={require("../../../assets/icons/iconFunctionAttendeeDefault24@2x.png")}
            alt=""
            style={{ width: "1.5em", height: "1.5em" }}
          />
        </Fab>
      </Tooltip>
      <div style={{ fontSize: "0.8em", fontWeight: 500 }}>{btnLabel}</div>
      <Drawer
        PaperProps={{
          style: { borderRadius: "0.5em", backgroundColor: "rgb(34,34,34)" },
        }}
        anchor="bottom"
        open={state}
        onClose={handleAttendeeListShown(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
